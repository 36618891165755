.userOtherWorks {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
}

.userOtherWorksCarousel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height:100%;
    margin: 10px 0px;
    position: relative
}

.userOtherWorksCarouselInner {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    transition:transform 0.3s;
    width:100%;
    height:100%
}

.userOtherWorksCarouselList {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.moveCarouselLeft {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: #777;
    color: white;
    font-size: 30px;
    left:10%;
    height:25px;
    width: 30px;
    border-radius: 50%;
    z-index: 6;
}
.moveCarouselRight {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: #777;
    color: white;
    font-size: 30px;
    right:10%;
    height:25px;
    width: 25px;

    border-radius: 50%;
}

.userOtherWorksImg {
    display: flex;
    justify-content: center;
    max-height:100%;
    object-fit: contain;
}

.userOtherWorksImg:hover {
    cursor: pointer;
}
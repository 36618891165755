/* body {
    overflow-y: scroll;
} */

.homePage {
    max-width: 100%;
    box-sizing: border-box;
    padding:50px 0px;
    background-color: rgb(240, 243, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.homeContent {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    margin-bottom: 100px;
}

.homeSideContainer {
    position:relative
}

.aboutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    position: sticky;
    top: 70px;
    width: 250px;
    margin: 20px 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 1px rgb(45, 45, 45);
}

.userContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    min-height: 300px;
    width: 250px;
    margin: 20px 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 1px rgb(45, 45, 45);
}

.userContainerTop {
    background-color: rgb(88, 139, 157);
    height:75px;
    width:100%;
    position:relative;
    border-radius: 10px 10px 0 0;
}

.userContainerSemiTop {
    position: absolute;
    top: 30px;
    left: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    z-index: 3;
}

.userContainerSemiTop:hover {
    cursor: pointer;
}

.userContainerImage {
    height:75px;
    width:75px;
    border-radius: 50%;
    object-fit: cover;
}

.userContainerImageDummy {
    font-size: 75px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color:rgb(66, 103, 96)
}

.userContainerName {
    text-align: center;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size:1.1rem;
    margin:5px 5px
}

.userContainerDesc {
    word-break: break-word;
    text-align: center;
    font-size:1rem;
    color:grey;
    margin:5px 5px
}

.userContainerBot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin:50px 0px 50px 0px
}

.postsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 10px;
}

.postsCreator {
    width: 100%;
    height: 100px;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 1px rgb(45, 45, 45);
    background-color: white;
}

.postsCreatorUpper {
    display: flex;
    flex-direction: row;
    margin: 15px 15px;
}

.postsCreatorButton {
    width:380px;
    height:40px;
    border: rgb(186, 186, 186) solid 1px;
    background-color: white;
    border-radius: 20px;
    text-align: left;
    transition: 0.2s;
}

.postsCreatorButton:hover {
    background-color: rgb(230, 230, 230);
}

.postsCreatorLower {
    display: flex;
    flex-direction: row;
    margin: 15px 15px;
}

.postContainer {
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 1px rgb(45, 45, 45);
    background-color: white;
    width:500px
}

.newsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height: 350px;
    width: 350px;
    margin: 20px 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 1px rgb(45, 45, 45);
}

.newsHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
    font-size: 1.1rem;
}

.newsBody {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    font-size: 1.1rem;
}

.newsList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.1rem;
}

.postCommentButton {
    border: none;
    border-radius: 20px;
    width:55px;
    height:30px;
    background-color: rgb(0,145,164);
}

.postCommentButton:hover {
    background-color: rgb(0, 118, 134);
}

.commentsList {
    height:100%;
    overflow-y: auto;
    margin: 20px 0px;
}

.commentUser {
    width:50px;
    padding: 6px;
    font-size:2rem;
    display: flex;
    justify-content: center;
    color:rgb(111, 186, 190)
}

.commentUserImage {
    height: 32px;
    width: 32px;
    object-fit: cover;
    border-radius: 50%
}

.commentUser:hover {
    cursor: pointer;
}

.commentBoxContent {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width:calc(100% - 50px);
    padding: 6px;
    background-color: rgb(243, 243, 243);
    border-radius: 8px;
}

.commentBoxHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height:45px
}

.commentUserContainer {
    max-width: 155px;
    display: flex;
    flex-direction: column
}

.commentUserInfo:hover {
    cursor: pointer;
}

.commentUserName {
    font-size: 15px;
    font-weight: 600;
    width: 100%;
}

.commentUserName:hover {
    cursor: pointer;
}

.commentUserDesc {
    width: 100%;
    font-size:0.8rem;
    color:grey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.commentBoxHeaderL {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;

}

.commentBoxHeaderR {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    position:relative;

    height: 20px
}

.timeStamp {
    color:grey;
    font-size: 12px;
    margin-right: 10px;
}

.commentBoxContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.commentBoxComment {
    color:black;
    font-size: 14px;
}

.errorMsgText {
    font-size: 12px;
    color: rgb(200, 0, 0);
}

.commentsContainer {
    flex-direction: column;
    transition: 0.3s;
    overflow: hidden;
    height:100%
}

.postButton {
    border-radius: 4px;
    height: 50px;
    width: 115px;
    background-color: white;
    border: none;
    font-size: 16px;
    transition: 0.3s;
    margin: 5px 5px;
}

.postButton:active:hover {
    background-color: rgb(195, 195, 195);
}

.postButton:hover {
    background-color: rgb(217, 217, 217);
}

.postBodyContainer {
    margin: 10px 0px;
    white-space: break-spaces;
    word-wrap: break-word;
}

.postQuickInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.quickComment {
    font-size:12px;
    color:grey
}

.quickComment:hover {
    text-decoration: underline;
    color: rgb(80, 80, 185);
}

.postHeader {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: space-between;
    height:50px;
}

.postOptionsContainer {
    display: flex;
    cursor:auto;
    flex-direction: column;
    position: relative;
    background-color: white;
    justify-content: center;
    align-items: center;
    top:45px;
    left:30px;
    box-shadow: 0px 0px 1px rgb(45, 45, 45);
    border-radius: 4px;
    width: 250px;
    height: 100px 
} 

.commentOptionsContainer {
    cursor:auto;
    flex-direction: column;
    position: absolute;
    background-color: white;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 1px rgb(45, 45, 45);
    border-radius: 5px 0px 5px 5px;
    width: 250px;
    height: 100px;
    top:30px;
    z-index: 1;
} 

.modalButton {
    display: flex;
    align-items: center;
    font-size: 16px;
    width:100%;
    height:40px;
    background-color: white;
    border: none
}

.modalButton:hover {
    cursor: pointer;
    background-color: rgb(242, 242, 242);
}

.commentinputBar {
    width: 100%;
    height: 35px;
    margin: 10px 0px
}

.commentinputBar input {
    width: 80%;
    padding: 0px 10px;
    height: 35px;
    border: 1px grey solid;
    border-radius: 30px;
}

.commentinputBar input:focus {
    outline: 1px grey solid;
}

.abtIco {
    color: black;
    text-decoration: none;
}

.abtIco:hover {
    text-decoration:none;
    color:rgb(61, 61, 61);
}

.postView {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    background-color: white;
    border-radius: 10px;
    max-width: 1200px;
    min-width: 500px;
    height: 800px;
    z-index: 4;
}

.postImageView {
    max-height: 800px;
    width: 100%;
    object-fit: cover;
}

.postViewContainer {
    display:flex;
    width: 100%;
    height:100%
}

.postViewHeader {
    display: flex;
    position: relative;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px 20px;
    border-bottom: rgb(204, 204, 204) solid 1px;
}

.postViewBody {
    box-sizing: border-box;
    width: max-content;
    padding: 20px 20px;
    height: 100%;
}

.postBodyModal {
    white-space: break-spaces;
    margin: 10px 0px;
    word-wrap: break-word;
}

.postText {
    border: none;
    outline: none;
    width: 100%;
    resize: none;
    min-height: 300px;
    height: fit-content;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

.createPostButton {
    float:right;
    background-color: rgb(0,145,164);
    border-radius: 20px;
    border:none;
    outline: none;
    color:white;
    padding: 0px 20px;
    height:40px
}

.createPostButton {
    cursor: pointer;
}

.createPostButtonDisabled {
    float:right;
    background-color: rgb(229, 229, 229);
    border-radius: 20px;
    border:none;
    outline: none;
    color:rgb(141, 140, 140);
    padding: 0px 20px;
    height:40px
}

.createPostButtonDisabled:hover {
    cursor:no-drop
}

.circleBackground {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 45px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    transition: 0.3s;
}

.circleBackground:hover {
    background-color: rgb(212, 212, 212);
}

.postBottom {
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: space-between;
}

.postBottomLabel {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: left;
    width:max-content
}

.imagesInput {
    text-align: center;
    width: 100px
}

input::file-selector-button {
    display: none;
}

.newsTextlineContainer {
    padding: 10px 10px;
}

.newsTextline {
    font-size: 15px;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.newsTextlineTime {
    margin-left: 16px;
    font-size: 14px;
    color: grey;
    overflow: hidden;
    text-overflow: ellipsis;
}

.newsTextlineContainer:hover {
    cursor: pointer;
    background-color: rgb(215, 215, 215);
}

.editUserimg {
    position:absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 45px;
    background-color: rgb(255, 255, 255);
    border: none;
    border-radius: 50%;
    transition: 0.3s;
}

.editUserimg:hover {
    background-color: rgb(212, 212, 212);
}
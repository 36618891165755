/* html, body {
    height:100vh
} */

#modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
}

#modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(91, 112, 131, 0.4);
    z-index: 4;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: white;
    border-radius: 10px;
    width: 400px;
    height: 500px;
    z-index: 4;
}

.postForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    background-color: white;
    border-radius: 10px;
    width: 550px;
    height: fit-content;
    z-index: 4;
}

.commentForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    background-color: white;
    border-radius: 10px;
    width: 550px;
    height: fit-content;
    z-index: 4;
}

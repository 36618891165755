.profPage {
    box-sizing: border-box;
    padding: 50px 0px;
    background-color: rgb(240, 243, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.profContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    height:90%;
    width:100%;
    margin-bottom: 100px;
}

.userPageContainer {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    width: 750px;
    min-width: 750px;
}

.userPageInfoCard {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    background-color: white;
    min-height: 400px;
    width: 750px;
    min-width: 750px;
    margin: 20px 10px 5px 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 1px rgb(45, 45, 45);
}

.userPageCard {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    background-color: white;
    min-height: 100px;
    width: 750px;
    min-width: 750px;
    margin: 5px 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 1px rgb(45, 45, 45);
}

.userPageContainerTop {
    background-color: rgb(88, 139, 157);
    height:200px;
    width:100%;
    position:relative;
    border-radius: 10px 10px 0 0;
}
.userPageContainerSemiTop {
    position: absolute;
    top: 90px;
    left: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 155px;
    width: 155px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    z-index: 3;
}

.userImage {
    height:150px;
    width:150px;
    border-radius: 50%;
    object-fit: cover;
    border:none;
}

.userImageDummy {
    font-size: 9.1rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color:rgb(66, 103, 96)
}

.profInfoContainer {
    margin: 20px 10px;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    color: black;
}

.profInfoName {
    margin: 50px 30px 2px 30px;
    font-size: 2rem;
}

.profInfoDesc {
    word-break:break-word;
    margin: 2px 30px 5px 30px;
    font-size: 1.2rem;
}

.profInfoLoc {
    margin: 5px 30px 10px 30px;
    font-size: 1rem;
    color: grey;
}

.sideContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height: 500px;
    width: 300px;
    margin: 20px 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 1px rgb(45, 45, 45);
} 

.sideContainerTitle {
    margin: 10px 10px;
    font-weight: bold;
}

.sideContainerItem {
    display: flex;
    width: 100%;
    margin: 10px 0px;
    padding: 0px 10px;
}

.cardBorder {
    margin:20px 20px
}

.cardContType {
    margin-bottom: 5px;
    font-size: 1.2rem;
    color: black;
}

.cardContInd {
    margin: 10px 0px 10px 15px;
}

.cardContTitle {
    font-size: 1.1rem;
    font-weight: bold;
    color: black;
}

.cardContSub {
    font-size: 1rem;
    color: black;
}

.cardContDesc {
    display: flex;
    align-items: center;
    color: grey;
}

.editUserButton {
    position:absolute;
    bottom:-30%;
    left:90%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 45px;
    background-color: rgb(255, 255, 255);
    border: none;
    border-radius: 50%;
    transition: 0.3s;
}

.editUserButton:hover {
    background-color: rgb(212, 212, 212);
}

.activityElementContainer {
    display: flex;
    flex-direction: column;
    margin: 5px 0px;
}

.activityElementEvent {
    font-size: 14px;
    color: grey;
}

.activityElementBody {
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
}
.navBarContainer {
    box-sizing: border-box;
    position: fixed;
    width:100vw;
    height: 55px;
    background-color: white;
    z-index: 3;
    top:0
}

.navBarShadow {
    box-sizing: border-box;
    box-shadow: 0px 0px 1px rgb(0 0 0)
}

.navBarInner {
    box-sizing: border-box;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    height:55px
}

.navBar {
    box-sizing: border-box;
    box-sizing: border-box;
    width:350px;
    min-width: 350px;
    display:flex;
    flex-direction:row;
    align-items: center;
}

.navLogo {
    height:35px;
    width:35px;
    object-fit:cover;
    float:left
}

.hovLink:hover {
    cursor: pointer;
}

.leftNav {
    justify-content: start;
    margin-left: 200px;
}

.rightNav {
    justify-content:end;
    margin-right: 200px;
}

.loggedInUser {
    color: black;
    display: flex;
    justify-content: center;
    width: 100%;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 4px;
}

.loggedInUser:hover {
    background-color: rgb(242, 242, 242);
}

.navBarLinks {
    width: 80px;
    padding: 10px 0px 8px 0px;
    text-decoration:none;
    display:flex;
    height: 100%;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    color:rgb(115, 115, 115);
    position: relative;
}

.navActive {
    border-bottom: rgb(0, 0, 0) 2px solid;
    color: black;
}

.navBarLinks:hover {
    cursor: pointer;
    color:black;
}

.navBarLinksCont {
    display:flex;
    height: 100%;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    color:rgb(115, 115, 115);
    text-decoration:none;
}

.navBarLinksCont:hover {
    color:black;
}

.userOptionsContainer {
    box-sizing: border-box;
    display: flex;
    cursor:auto;
    flex-direction: column;
    position: absolute;
    background-color: white;
    justify-content: center;
    align-items: center;
    top: 64px;
    box-shadow: 0px 0px 1px rgb(45, 45, 45);
    border-radius: 4px;
    width: 250px;
    min-height: 100px;
}
.searchDropdownContainer {
    box-sizing: border-box;
    display: flex;
    cursor:auto;
    flex-direction: column;
    position: absolute;
    background-color: white;
    justify-content: center;
    align-items: center;
    top: 50px;
    box-shadow: 0px 0px 1px rgb(45, 45, 45);
    border-radius: 4px;
    width: 400px;
    min-height: 100px;
    z-index: 4;
}

.searchFiller {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 5px 5px;
}

.userSearchContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 5px 5px;
}

.userSearchContainerInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width:100%;
    padding: 5px 5px;
    
}

.userSearchContainer:hover {
    background-color: rgb(233, 233, 233);
    cursor: pointer;
}

.userSearchContainer > * {
    margin: 0px 8px;
    font-size: 0.8rem
}

.searchBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgb(241, 255, 253);
    margin: 0 10px;
    border-radius: 5px;
    transition: 0.3s;
}

.searchBar:focus-within {
    border: black 1px solid;
    flex-grow: 1;
}

.searchBar > i {
    margin: 0px 10px;
    font-size: 0.8rem;
}

.searchBar > input {
    border: none;
    background-color: none;
    background: none;
}

.searchBar > input:focus {
    border: none;
    outline: none;

}

.searchName {
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size:1rem;
    font-weight: bold;
}

.searchDesc {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size:0.8rem;
    color:grey;
    flex-grow: 1
}

.joinNow:hover{
    background-color: rgb(225, 225, 225);
}

.signIn:hover{
    background-color: rgb(240, 255, 254);
}

.navBarLinks:hover .meArrow {
    animation: Slide 0.7s linear infinite;
}

@keyframes Slide {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(2px);
    }
}
/* TODO Add site wide styles */
html {
    width: 100vw;
}

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}